import { Message, toaster } from "rsuite";
import NetworkService from "../NetworkService";
import jsPDF from "jspdf";
import font from "../static/Roboto-mono.js";
import QRCode from "qrcode";

export const downloadFile = (data, name) => {
  const blobUrl = window.URL.createObjectURL(data);
  const a = document.createElement("a");
  a.href = blobUrl;
  a.download = `${name}.pdf`;
  a.click();
  URL.revokeObjectURL(blobUrl);
};

export const downloadInvoices = (invoices) => {
  invoices.forEach((invoiceItem) => {
    const base64ImageData = `data:image/jpg;base64,${invoiceItem?.invoice}`;
    downloadBase64Image(base64ImageData, invoiceItem.id);
  });
};

function downloadBase64Image(base64Data, fileName) {
  const blob = dataURItoBlob(base64Data);

  const blobUrl = URL.createObjectURL(blob);

  const downloadLink = document.createElement("a");
  downloadLink.href = blobUrl;
  downloadLink.download = fileName + ".jpg";
  downloadLink.click();
  URL.revokeObjectURL(blobUrl);
}

function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpg" });
}

export const refetchCompanyData = (setCompanyData) => {
  NetworkService.getTodaysData().then((res) => {
    setCompanyData(res);
  });
};

export const getUserById = (userId, setUserData) => {
  NetworkService.getUserById(userId).then((res) => {
    setUserData(res[0]);
  });
};

export const downloadPDFs = (invoices) => {
  invoices.forEach((invoiceItem) => {
    const base64ImageData = `data:image/jpg;base64,${invoiceItem?.invoice}`;
    generatePDF(base64ImageData, invoiceItem.id);
  });
};

export const generatePDF = (base64Image, fileName) => {
  console.log(base64Image);
  const doc = new jsPDF();
  const img = new Image();
  img.src = base64Image;
  img.onload = () => {
    const originalWidth = img.naturalWidth;
    const originalHeight = img.naturalHeight;

    const pdfWidth = 50;
    const pdfHeight = (originalHeight / originalWidth) * pdfWidth;

    const imgProps = {
      format: "JPEG",
      x: 10,
      y: 10,
      width: pdfWidth,
      height: pdfHeight,
    };

    doc.addImage(base64Image, "JPEG", imgProps.x, imgProps.y, imgProps.width, imgProps.height);
    doc.save(`${fileName || "download"}.pdf`);
  };
};

export const toasterFunction = (message, options = {}) => {
  console.log("run");
  toaster.push(message, { placement: options?.placement || "topCenter", duration: options?.duration || 5000 });
};

export const Classes = {
  1: "Mini",
  2: "Eco",
  4: "Biz",
};

export const CarClasses = {
  1: { label: "Mini", description: "Pet friendly, Extra Luggage" },
  2: { label: "Eco", description: "Child Seat, Extra Luggage" },
  4: { label: "Biz", description: "Extra Luggage" },
};

export const Days = {
  monday: {
    label: "Monday",
  },
  tuesday: {
    label: "Tuesday",
  },
  wednesday: {
    label: "Wednesday",
  },
  thursday: {
    label: "Thursday",
  },
  friday: {
    label: "Friday",
  },
  saturday: {
    label: "Saturday",
  },
  sunday: {
    label: "Sunday",
  },
};

export function compareTimes(a, b) {
  const aSplit = a.split(":");
  const bSplit = b.split(":");

  const aHour = Number(aSplit[0]);
  const bHour = Number(bSplit[0]);

  const aMinute = Number(aSplit[1]);
  const bMinute = Number(bSplit[1]);

  if (aHour > bHour) {
    return -1;
  }
  if (bHour > aHour) {
    return 1;
  }
  if (aMinute > bMinute) {
    return -1;
  }
  if (bMinute > aMinute) {
    return 1;
  }
  return 0;
}

export function validWorkingHoursForADay(data) {
  let prev = undefined;

  for (let item of data) {
    if (compareTimes(item[0], item[1]) === -1) {
      toasterFunction(
        <Message closable type="error">
          Error in timetables values!
        </Message>
      );
      return false;
    }
    if (prev && compareTimes(prev[1], item[0]) === -1) {
      toasterFunction(
        <Message closable type="error">
          Error in timetables values!
        </Message>
      );
      return false;
    }
    prev = item;
  }

  return true;
}

export const newFiscalPdfDownload = (invoices) => {
  invoices.forEach((invoiceItem) => {
    createPdfNewFiscal(invoiceItem);
  });
};

export const createPdfNewFiscal = async (payload) => {
  const rawData = payload.journal;

  console.log(payload)

  let cleanedData = rawData;

  const lines = cleanedData.split("\n");
  const margin = 5;

  const doc = new jsPDF({
    format: [150, lines.length * 10 + 160],
  });

  doc.addFileToVFS("MyFont.ttf", font);
  doc.addFont("MyFont.ttf", "MyFont", "normal");
  doc.setFont("MyFont");
  doc.setFontSize(16);

  let y = margin;
  const lineHeight = 10;

  const qrCodeData = await QRCode.toDataURL(payload.verification_url, {
    errorCorrectionLevel: "Q",
    width: 200,
    color: {
      dark: "#000000", // Black dots
      light: "#ffffff", // White background
    },
  });

  lines.forEach((line, key) => {
    const wrappedText = doc.splitTextToSize(line, 150 - 2 * margin);
    wrappedText.forEach((text) => {
      if (y > doc.internal.pageSize.height - margin) {
        doc.addPage();
        y = margin;
      }
      if (key === lines.length - 2) {
        doc.addImage(qrCodeData, "PNG", 0, y, 150, 150); // x, y, width, height
        y += lineHeight + 150;
      }
      if (key > 0 && key < 6) {
        const docWidth = doc.internal.pageSize.getWidth();
        const textWidth = doc.getTextWidth(text);
        doc.text(text.trim(), (docWidth - textWidth) / 2, y);
      } else {
        doc.text(text, margin, y);
      }
      y += lineHeight;
    });
  });
  doc.save(`cargo-invoice-${payload.drive_id}.pdf`);
};
